import React, { useContext, useEffect, useState } from 'react';

import {
  Grow,
  Box,
  Button
} from '@mui/material';

import Image from 'mui-image';
import Step1 from './steps/1';
import Step2 from './steps/2';
import Step3 from './steps/3';

import { UserContext, EventsContext } from '@context';
import { useHistory } from 'react-router-dom';

export default function Index(){
  const [activeStep, setActiveStep] = useState(1);
  const { handleLogout } = useContext(UserContext);
  const history = useHistory();
  const { trackEvent } = useContext(EventsContext);

  useEffect(() => {
    if(activeStep == 1){
      trackEvent('Onboarding:ProblemSolution:Open');
    }
    if(activeStep == 2){
      trackEvent('Onboarding:Carousel:Open');
    }
  }, [activeStep]);

  const Step = (props) => {
    const { children, includeBackgroundImage, addPaddingBottom } = props;

    return (
      <Box sx={{ py: 0, 
        paddingTop: includeBackgroundImage ? 0 : 2, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        {includeBackgroundImage &&
            <Box>
              <Image 
                duration={500} 
                width='100vw' 
                src='https://bracesocialprod.blob.core.windows.net/static/brace_man_running_in_woods.png'/>
            </Box>
        }
        {!includeBackgroundImage &&
          <Box>
            <Image 
              duration={500} 
              width='200px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
          </Box>
        }
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            {children}
          </Box>
          <Box sx={{ paddingBottom: addPaddingBottom ? 6 : 1, display: 'flex', justifyContent: 'center' }}>
            <Button 
              color='braceGreenDark'
              onClick={() => handleLogout(() => {
                trackEvent('Onboarding:User:LogOut');
                history.push('/');
              })}>
            Logout
            </Button>
          </Box>
        </Box>
      </Box>
    );

  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Grow timeout={200}
      in={true}
      mountOnEnter
      unmountOnExit>
      <Box sx={{ 
        height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))', 
        px: 1, 
        backgroundColor: '#FAFAFA',
        display: 'flex' , 
        flexDirection:'column', 
        alignItems:'center' 
      }}>
          
        {activeStep == 1 && 
          <Step includeBackgroundImage={true} addPaddingBottom={true}>
            <Step1 handleNext={handleNext} />
          </Step>
        }
        {activeStep == 2 && 
          <Step>
            <Step2 handleNext={handleNext} handleBack={handleBack} />
          </Step>
        }
        {activeStep == 3 && 
          <Step3 handleNext={handleNext} />
        }
      </Box>
      
    </Grow>
  );
}

