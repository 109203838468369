import React, { useCallback, useContext, useState } from 'react';

import { 
  Box,
  Card,
  Typography,
  Stack,
  IconButton
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { RecoveryContext } from '@context';

import Section from './section';
import {  LoadableList, LoadableText } from '@components/atoms';
import { useHistory } from 'react-router-dom';
import { NewPlanDialog } from '@components/templates';
import EmptySection from './empty-section';
import { toShortDateString } from '@helpers/dates';

export default function Plans(){
  const PLANS_SECTION_HEIGHT_IN_PX = 50;
  const { plans, plansIncludedDraftsAndArchived, isLoadingPlans, loadPlans } = useContext(RecoveryContext);
  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false);
  const history = useHistory();
  const [numberOfRows, setNumberOfRows] = useState(2);

  const Plan = (props) => {
    const { plan } = props;

    return (
      <Card variant='outlined'
        sx={{ p: 1, display: 'flex', alignItems: 'center' }}
        onClick={() => history.push(`/recovery/plans/${plan.id}`)} 
        data-testid={`recovery-manage-plans-plan-${plan.id}`}
      >
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography component='div' variant='body1'>
            <LoadableText isLoading={isLoadingPlans} text={plan?.name} minWidth={200}/>
          </Typography>
          <Typography component='div' variant='body2'>
            {plan?.assignedByUserName &&
              <LoadableText isLoading={isLoadingPlans} text={`Assigned : ${plan?.assignedByUserName}`} minWidth={100}/>
            }
            {!plan?.assignedByUserName &&
              <LoadableText isLoading={isLoadingPlans} text={`Created : ${toShortDateString(plan?.createdAt)}`} minWidth={100}/>
            }
           
          </Typography>
        </Box>
        <IconButton onClick={() => history.push(`/recovery/plans/${plan.id}`)} sx={{ py: 0, paddingRight: 0 }}><ChevronRightIcon /></IconButton>
      </Card>
    );
  };

  const plansStackRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        setNumberOfRows(Math.max(1,
          Math.floor(height / PLANS_SECTION_HEIGHT_IN_PX))
        );
      }
    });
    resizeObserver.observe(node);
  }, []);

  return (
    <Section title='Plans' 
      buttonDataTestId={plansIncludedDraftsAndArchived.length > 0 ? 'recovery-plans-manage' : 'recovery-plans-add'}
      buttonText={plansIncludedDraftsAndArchived.length > 0 ? 'View' : null}
      buttonDisabled={isLoadingPlans}
      buttonAction={() => { plansIncludedDraftsAndArchived.length > 0 ? 
        history.push('/recovery/plans')
        : setIsPlanDialogOpen(true);} }
      buttonIcon= {plansIncludedDraftsAndArchived.length > 0 ? 
        <ChevronRightIcon /> 
        : <AddCircleIcon />}
    >
      {(!isLoadingPlans && plans.length == 0) &&
      <>
        <EmptySection 
          dataTestId='recovery-plan-the-first-step'
          iconType='plus-circle'
          message=' Create an exercise plan to assign to your programs'
          action={() => setIsPlanDialogOpen(true)}/>
        <NewPlanDialog isOpen={isPlanDialogOpen}
          close={(shouldRefresh) => {
            if(shouldRefresh){
              loadPlans(shouldRefresh);
            }
            setIsPlanDialogOpen(false);
          }}/>
      </>
      }
      {(isLoadingPlans || plans.length > 0) &&
        <Stack spacing={1} sx={{ flexGrow: 1 }} ref={plansStackRef}>
          <LoadableList 
            numberOfRows={numberOfRows}
            isLoading={isLoadingPlans}
            rows={plans.slice(0, numberOfRows)}
            getRow={(plan) => <Plan 
              key={plan.id}
              plan={plan}
            /> }
          />
        </Stack>
      }
    </Section>
  );
}

