import React, { useContext, useEffect, useState } from 'react';

import { 
  Box 
} from '@mui/material';

import { Page } from '@components/v2/organisms';

import { EventsContext } from '@context/events';
import { UserContext, RecoveryContext } from '@context';
import Plans from './plans';
import Programs from './programs';
import Sessions from './sessions';
import DayScroller from './day-scroller';

export default function Home(){
  const { user } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { loadAll, isLoadingPlans, plans, activeDay } = useContext(RecoveryContext);
  const [ areSessionsExpanded, setAreSessionsExpanded ] = useState(false);

  useEffect(() => {
    if(user){
      trackEvent('Recovery:Open');
      loadAll(true);
    }
  }, [user]);

  useEffect(() => {
    setAreSessionsExpanded(false);
  }, [activeDay?.valueOf()]);

  const hasNoPlans = !isLoadingPlans && plans.length == 0;

  return (
    <Page title='Recovery'>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', paddingTop: 1 }}>
        <Box sx={{ width: '100%' }}>
          <DayScroller />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {hasNoPlans &&
          <Box sx={{ 
            height: '40%', 
            width: '100%' 
          }}>
            <Plans />
          </Box>
          }
          <Box sx={{ height: 
            hasNoPlans ? '30%' 
              : areSessionsExpanded ? 
                'auto' : 
                '45%', 
          width: '100%' 
          }}>
            <Sessions areSessionsExpanded={areSessionsExpanded} toggleExpansion={() => setAreSessionsExpanded(!areSessionsExpanded)}/>
          </Box>
          <Box sx={{ height: hasNoPlans ? 
            '30%' 
            : '30%', 
          width: '100%' 
          }}>
            <Programs />
          </Box>
          {!hasNoPlans &&
          <Box sx={{ 
            height: '25%', 
            width: '100%' 
          }}>
            <Plans />
          </Box>
          }
        </Box>
      </Box>
    </Page>
  );
}

