import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { 
  Card,
  CardContent,
  Typography,
  Box,
  Link
} from '@mui/material';

import { BillingContext } from '@context/billing';
import { toShortDateString, getDaysFromNow } from '@helpers/dates';
import { titleCase } from '@helpers/text';
export default function Subscription(){
  const { subscription, rcSubscription } = useContext(BillingContext);
  return (
    <Card>
      <CardContent>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="text.secondary"
              variant='h5'
              gutterBottom
              sx={{ flexGrow: 1 }}>
              Subscription
            </Typography>
            {rcSubscription?.managementUrl && !rcSubscription.unsubscribedAt &&
            <Box>
              <Link 
                sx={{ textDecoration: 'none' }}
                href={rcSubscription.managementUrl}
              >Manage</Link>
            </Box>
            }
            {(rcSubscription?.managementUrl && rcSubscription.unsubscribedAt) &&
            <Box>
              <Link 
                sx={{ textDecoration: 'none' }}
                href={'#/paywall'}
              >
                Resubscribe
              </Link>
            </Box>
            }
            {(!rcSubscription?.managementUrl && subscription?.isTrial) &&
            <Box>
              <Link 
                sx={{ textDecoration: 'none' }}
                href='#/paywall'
              >
                Update Plan
              </Link>
            </Box>
            }
          </Box>
        </Box>
      
        {subscription?.isTrial &&
          <Box>
            <Typography variant='caption' color='text.secondary' fontWeight='light'>
              Current Plan
            </Typography>
            <Typography>
              {`Brace Free Trial - ${
                getDaysFromNow(subscription.expiresAt) > 1 ?
                  `${getDaysFromNow(subscription.expiresAt)} Days Left` :
                  '1 Day Left'
              }`}
            </Typography>
            <Typography 
              variant='caption'
              color='text.secondary'
              fontWeight='light'>
              Ends At
            </Typography>
            <Typography>
              {toShortDateString(subscription.expiresAt)}
            </Typography>
            
          </Box>
        }
        {(subscription && !subscription?.isTrial) &&
          <Box>
            <Typography variant='caption' color='text.secondary' fontWeight='light'>
              Current Plan
            </Typography>
            <Typography>
              {titleCase(subscription?.renewalPeriod?.toLowerCase().replace('_', ' '))}
            </Typography>
            <Typography 
              variant='caption'
              color='text.secondary'
              fontWeight='light'>
              {subscription?.unsubscribedAt ? 'Ends At' : 'Next Payment'}
            </Typography>
            <Typography>
              {toShortDateString(subscription.expiresAt)}
            </Typography>
            
          </Box>
        }
      </CardContent>
    </Card>
  );
}