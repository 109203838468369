import React, { useContext } from 'react';

import {
  Box,
  Typography
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';

import { LoadableText } from '@components/atoms';

import { Divider } from '@components/v2/atoms';
import { AccountOnboardingContext } from '@context';
import AccountOnboardingLoadingBar from './loading-bar';
import { useTheme } from '@emotion/react';

export default function OnboardingBanner(props) {
  const { openDialog } = props;
  const theme = useTheme();
  var { accountOnboarding, isLoadingAccountOnboarding } = useContext(AccountOnboardingContext);
  if(accountOnboarding == null || accountOnboarding?.isComplete === true){
    return <></>;
  }

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.braceGreenDark.main,
      color: theme.palette.braceGreenDark.contrastText
    }}>
      <Box data-testid='account-onboarding-banner'
        sx={{ 
          paddingBottom: 1
        }}>
        <AccountOnboardingLoadingBar />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 }}
          onClick={() => {
            if(openDialog){
              openDialog();
            }

          }}>
          <Box>
            <Typography variant='h5'
              component='div'
              fontWeight='medium'
              color={theme.palette.braceGreenLight.main}>
              <LoadableText minWidth='27px' isLoading={isLoadingAccountOnboarding} text={`${accountOnboarding.completeCount}/${accountOnboarding.totalCount}`} />
            </Typography>
          
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant='h5' fontWeight='medium'>
            Become A Brace Pro
            </Typography>
            <Typography variant='body2' fontWeight='medium' color={theme.palette.braceGreenLight.main}>
              10% Off Subscription for Pros!
            </Typography>
          
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent:'center', lineHeight: 1 }}>
            <StarRateIcon sx={{ fontSize: 30 }}  color='secondary' />
          </Box>
        </Box>
      
      </Box>
      <Divider light/>
    </Box>
  );
}