import React, { useContext, useEffect } from 'react';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import { 
  Box 
} from '@mui/material';

//Pages
import { default as HomeContainer } from './pages/home/new';
import { default as IndexContainer } from './pages/index/index';
import { default as CommunityContainer } from './pages/community/index';
import { default as ProfileUpdateContainer } from './pages/home/new/profile-update';
import { default as FeedbackContainer } from './pages/feedback/index';
import { default as ReportContainer } from './pages/report/index';
import { default as NotificationsContainer } from './pages/notifications/index';
import { default as OnboardingContainer } from './pages/onboarding/index';
import { default as OnboardingOptionsContainer } from './pages/onboarding/options';
import { default as DiaryViewContainer } from './pages/home/components/home-profile/diary/view/index';
import { default as TrophiesContainer } from './pages/trophies';
import { default as SettingsContainer } from './pages/settings';
import { default as PaywallContainer } from './pages/paywall';
import { default as LearnContainer } from './pages/learn';

import { default as RecoveryContainer } from './pages/recovery/v3';
import { default as ProgramCreatorContainer } from './pages/recovery/v3/components/programs/creator';
import ManagePlans from './pages/recovery/plans/manage';
import ManagePrograms from './pages/recovery/programs/manage';
import ViewPrograms from './pages/recovery/programs/view';
import ViewPlan from './pages/recovery/plans/view';
import ViewSession from './pages/recovery/sessions/view';

import AppModal from './components/modals/app-modal';
import NewPostModal from './components/organisms/new-post-modal';
import { LoadingPage } from '@components/molecules';
import { NewPost } from '@components/organisms';
import { Profile } from '@components/templates';
import { UserContext } from '@context/user';

import WebApp from './../web/app';
import { Capacitor } from '@capacitor/core';

export default function App() {
  const { user, isLoading } = useContext(UserContext);
  useEffect(() => {
    if(Capacitor.getPlatform() != 'web'){
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  }, []);

  if(isLoading){
    return (    
      <LoadingPage />
    );
  }

  return (
    <>
      {user?.role == 'PRACTITIONER' &&
       <WebApp />
       
      }
      {user?.role != 'PRACTITIONER' &&
         <Router>
           <Box sx={{ 
             width: '100%', 
             padding:'env(safe-area-inset-top,0) env(safe-area-inset-right,0) env(safe-area-inset-bottom,0) env(safe-area-inset-left,0)' }}>
             <Switch>
               <Route path="/index">
                 <IndexContainer />
               </Route>
               <Route exact path="/profile/edit">
                 <ProfileUpdateContainer />
               </Route>
               <Route exact path="/profile/:id">
                 <HomeContainer />
               </Route>
               <Route exact path="/diary">
                 <DiaryViewContainer />
               </Route>
               <Route exact path="/community">
                 <CommunityContainer />
               </Route>
               <Route path="/recovery/program/creator">
                 <ProgramCreatorContainer />
               </Route>
               <Route path="/recovery/plans/:id">
                 <ViewPlan />
               </Route>
               <Route path="/recovery/plans">
                 <ManagePlans />
               </Route>
               <Route path="/recovery/programs/:id">
                 <ViewPrograms />
               </Route>
               <Route path="/recovery/programs">
                 <ManagePrograms />
               </Route>
               <Route path="/recovery/sessions/:id">
                 <ViewSession />
               </Route>
               <Route path="/recovery">
                 <RecoveryContainer />
               </Route>
               <Route path="/feedback">
                 <FeedbackContainer />
               </Route>
               <Route path="/report">
                 <ReportContainer />
               </Route>
               <Route path="/onboarding/options">
                 <OnboardingOptionsContainer />
               </Route>
               <Route path="/onboarding/info">
                 <OnboardingContainer />
               </Route>
               <Route path="/notifications">
                 <NotificationsContainer />
               </Route>
               <Route path="/index">
                 <IndexContainer />
               </Route>
               <Route exact path="/home">
                 <HomeContainer />
               </Route>
               <Route exact path="/trophies">
                 <TrophiesContainer />
               </Route>
               <Route exact path="/settings">
                 <SettingsContainer />
               </Route>
               <Route path="/paywall">
                 <PaywallContainer />
               </Route>
               <Route path="/learn">
                 <LearnContainer />
               </Route>
               <Route exact path="/">
                 <IndexContainer />
               </Route>
             </Switch>
             <AppModal />
             <NewPostModal />
             <NewPost />
             <Profile />
           </Box>
         </Router>
      }
    </>
  );
}