import React, { useContext, useEffect, useState } from 'react';

import { 
  IconButton,
  Box,
  Typography,
  Divider,
  Card,
  Stack,
  Grid
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { Page } from '@components/v2/organisms';
import SubPage from '../../../../components/page/sub-page';
import { NewPlanDialog } from '@components/templates';
import { UserContext, ServicesContext, EventsContext } from '@context';
import { useParams } from 'react-router-dom';
import { ConfirmationModal, ExerciseInfoDialog } from '@components/molecules';
import { useHistory } from 'react-router-dom';
import { LoadableList, LoadableText } from '@components/atoms';
import { titleCase } from '@helpers/text';
import { toShortDateTimeString } from '@helpers/dates';
import { RecoveryContext } from '@context/recovery';

export default function ViewPlan(){
  let { id } = useParams();
  const history = useHistory();
  const { trackEvent } = useContext(EventsContext);
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { loadPlans } = useContext(RecoveryContext);
  const [plan, setPlan] = useState(null);
  const [isLoadingPlan, setIsLoadingPlan] = useState(true);
  const [shouldShowDeletePlanConfirmationDialog, setShouldShowDeletePlanConfirmationDialog] = useState(false);
  const [shouldShowRestorePlanConfirmationDialog, setShouldShowRestorePlanConfirmationDialog] = useState(false);
  const [shouldShowEditPlanDialog, setShouldShowEditPlanDialog] = useState(false);

  const loadPlan = async () => {
    setIsLoadingPlan(true);
    var result = await api.get({ url: `recovery/plans/${id}`, getUserToken });
    setPlan(result);
    setIsLoadingPlan(false);
  };

  const archivePlan = async () => {
    setIsLoadingPlan(true);
    await api.delete({ url: `recovery/plans/${id}`, getUserToken });
    history.goBack();
    loadPlans(true);
    setIsLoadingPlan(false);
  };

  const restorePlan = async () => {
    setIsLoadingPlan(true);
    var result = await api.put({ url: `recovery/plans/${id}/restore`, getUserToken });
    setPlan(result);
    loadPlans(true);
    setIsLoadingPlan(false);
  };

  useEffect(() => {
    if(user){
      trackEvent('Recovery:Plans:View');
      loadPlan();
    }
  }, [user]);

  const Exercise = (props) => {
    const { exercise } = props;
    const [areNotesVisible, setAreNotesVisible] = useState(false);
    return(
      <Card variant='outlined' sx={{ px: 1, paddingTop: 1, paddingBottom: areNotesVisible ? 1 : 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight='Medium' component='div' sx={{ flexGrow: 1 }}>
            <LoadableText text={titleCase(exercise.definition?.name)} isLoading={isLoadingPlan}/>
          </Typography>
          <Typography component='div' sx={{ paddingRight: 1 }}>
            <LoadableText text={`Sets : ${exercise?.sets?.length}`} isLoading={isLoadingPlan}/>
          </Typography>
          <ExerciseInfoDialog exercise={exercise?.definition} isDisable={isLoadingPlan} />
        </Box>
        {exercise?.notes &&
          <Box sx={{ display : 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {areNotesVisible && 
            <Card variant='outlined' sx={{ border: 'none' }}>
              <Typography variant='caption' color='text.secondary'>Exercise Notes</Typography>
              <Typography component='div'
                sx={{ pre: { 
                  fontSize: 'inherit',
                  color: 'inherit',
                  border: 'initial',
                  padding: 'initial',
                  fontFamily: 'inherit',
                  overflowX: 'auto',
                  margin: 0
                } }  }>
                <pre>
                  <LoadableText 
                    isLoading={isLoadingPlan}
                    text={exercise?.notes} 
                    sx={{
                      overflowX: 'auto',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }} 
                  />
                </pre>
              </Typography>
            </Card>
            }
            <Box 
              sx={{ display : 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}
              onClick={() => setAreNotesVisible(!areNotesVisible)}>
              {areNotesVisible && <ExpandLessIcon sx={{ p: 0, fontSize: '1rem' }} />}
              <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>{`${areNotesVisible ? 'Hide' : 'Show'} Notes`}</Typography>
              {!areNotesVisible && <ExpandMoreIcon sx={{ p: 0, fontSize: '1rem' }} />}
             
            </Box>
          </Box>
        }
      </Card>
    );
  };

  return (
    <Page>
      <SubPage 
        title={plan?.name}
        isLoading={isLoadingPlan}
        isBackButtonEnabled={true}
        endButton={
          <Box sx={{ display: 'flex', marginRight: 0.5 }}>
            {plan?.deletedAt ?
              <IconButton  data-testid='recovery-view-plan-restore-button'
                sx={{ px: 0.5 }}
                disabled={isLoadingPlan}
                onClick={() => {setShouldShowRestorePlanConfirmationDialog(true);}}>
                <UnarchiveIcon/>
              </IconButton>
              :
              <IconButton  data-testid='recovery-view-plan-archive-button'
                sx={{ px: 0.5 }}
                disabled={isLoadingPlan}
                onClick={() => {setShouldShowDeletePlanConfirmationDialog(true);}}>
                <ArchiveIcon />
              </IconButton>
            }
            {!plan?.assignedByUserId &&
              <IconButton data-testid='recovery-view-plan-edit-button'
                sx={{ paddingLeft: 0.5 }}
                disabled={isLoadingPlan}
                onClick={() => setShouldShowEditPlanDialog(true)}><EditIcon /></IconButton>
            }
          </Box>}
      >
        <Box>
          <Box sx={{ p: 1 }}>
            <Box>
              <Typography variant='body1' component='div'>
                <LoadableText isLoading={isLoadingPlan} text={`Exercises : ${plan?.exercises.length}`} />
              </Typography>
            </Box>
            {plan?.notes &&
              <>
                <Typography variant='caption' color='text.secondary'>Plan Notes</Typography>
                <Typography component='div'
                  sx={{ pre: { 
                    fontSize: 'inherit',
                    color: 'inherit',
                    border: 'initial',
                    padding: 'initial',
                    fontFamily: 'inherit',
                    margin: 0
                  } }  }>
                  <pre>
                    <LoadableText 
                      isLoading={isLoadingPlan} 
                      text={plan?.notes}  
                      sx={{
                        overflowX: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word'
                      }}  />
                  </pre>
                </Typography>
              </>
            }
            {plan?.assignedByUserName  &&
             <> 
               <Typography variant='caption' color='text.secondary'>Assigned By</Typography>
               <Typography variant='body2' component='div'>{plan?.assignedByUserName}</Typography>
             </>
            }
            <Grid container sx={{ width: '100%', paddingBottom: 0.5 }}>
              <Grid item xs={6}>
                <Typography variant='caption' color='text.secondary'>Last Updated</Typography>
                <Typography variant='body2' component='div'>
                  <LoadableText isLoading={isLoadingPlan} text={toShortDateTimeString(plan?.updatedAt ?? plan?.createdAt)} />
                </Typography>
              </Grid>
              {plan?.deletedAt  &&
                <Grid item xs={6}>
                  <Typography variant='caption' color='text.secondary'>Archived At</Typography>
                  <Typography variant='body2' component='div'>{toShortDateTimeString(plan?.deletedAt)}</Typography>
                </Grid>
              }
            </Grid>

          </Box>
          <Divider />
          <Box sx={{ p: 1 }}>
            <Typography variant='h6' gutterBottom>
              Exercises
            </Typography>
            <Stack spacing={1}>
              {!isLoadingPlan ? 
                plan?.exercises.map(e => 
                  <Exercise exercise={e} key={e.definition.id} />
                ) 
                : <LoadableList isLoading={true} getRow={(p, index) => <Exercise key={index} exercise={{}}/>}/>
              }
            </Stack>
          </Box>
        </Box>

      </SubPage>
      <ConfirmationModal 
        isOpen={shouldShowDeletePlanConfirmationDialog} 
        title='Archive Plan?' 
        message={'If you archive this plan then you will no longer be able to create sessions or programs from it. You can restore it at a later date though.'}
        cancelText={'Cancel'}
        handleClose={() => {
          setShouldShowDeletePlanConfirmationDialog(false);
        }}
        confirmText={'Archive'}
        isDeleteAction={true}
        confirmationSuccessMessage={`Exercise plan "${plan?.name}" successfully archived."`}
        handleConfirmation={() => archivePlan()} />
      <ConfirmationModal 
        confirmDataTestId='recovery-view-plan-restore-confirmation-modal-button'
        isOpen={shouldShowRestorePlanConfirmationDialog} 
        title='Restore Plan?' 
        message={'If you restore this plan you will then be able to create sessions and programs from it.'}
        cancelText={'Cancel'}
        handleClose={() => {
          setShouldShowRestorePlanConfirmationDialog(false);
        }}
        confirmText={'Restore'}
        confirmationSuccessMessage={`Exercise plan "${plan?.name}" successfully restored."`}
        handleConfirmation={() => restorePlan()} />
      <NewPlanDialog isOpen={shouldShowEditPlanDialog}
        planBeingEdited={plan}
        close={(shouldRefresh) => {
          if(shouldRefresh){
            loadPlan();
            loadPlans(true);
          }
          setShouldShowEditPlanDialog(false);
        }}/>
    </Page>
  );
}

