import React, { useContext, useEffect, useState } from 'react';

import { 
  Box,
  Typography
} from '@mui/material';

import { ServicesContext, UserContext  } from '@context';
import moment from 'moment';
import { LoadableText, MoodEmoji } from '@components/atoms';
import { Card } from '@components/v2/atoms';
import CreateDiaryDialog from '../../components/home-profile/diary/create-dialog';
import { moodToText } from '@helpers/text';
import ToDoItem from './to-do-item';

export default function DiaryToDo(){
  const { user, getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const [todaysDiaryEntry, setTodaysDiaryEntry] = useState(null);
  const [diaryStreak, setDiaryStreak] = useState(0);
  const [isLoadingDiaryEntries, setIsLoadingDiaryEntries] = useState(true);
  const [isCreateDiaryOpen, setIsCreateDiaryOpen] = useState(false);
  const today = moment();

  const loadDiaryEntries = async () => {
    setIsLoadingDiaryEntries(true);
    var result = await api.get({ url: 'community/diary/search', getUserToken });
    setTodaysDiaryEntry(result.results.filter(d => moment(d.createdAt).isAfter(today.startOf('day')))[0]);

    var count = 0;
    var hasDiaryOnDay = true;

    do {
      hasDiaryOnDay = result.results.filter(d => 
        moment().add(-(count), 'days').startOf('day').isBefore(moment(d.createdAt)) && 
        moment().add(-(count - 1), 'days').startOf('day').isAfter(moment(d.createdAt))
      ).length > 0;
      if(hasDiaryOnDay)
        count++;
    } while (hasDiaryOnDay);

    setDiaryStreak(count);
    setIsLoadingDiaryEntries(false);
  };

  useEffect(() => {
    if(user?.id){
      loadDiaryEntries();
    }
  }, [user]);

  const getIconForMood = () => {
    switch(todaysDiaryEntry?.mood){

    case 1:
      return 'sentiment-angry';
    case 2:
      return 'sentiment-tired';
    case 3:
      return 'sentiment-ok';
    case 4:
      return 'sentiment-content';
    case 5:
      return 'sentiment-delighted';
    default:
    }
  };

  return (
    <Box data-testid='home-diary-to-do'>
      <>
        {!todaysDiaryEntry && 
            <ToDoItem 
              dataTestId='home-diary-to-do'
              headerDataTestId='home-diary-to-do-header'
              isLoading={isLoadingDiaryEntries}
              onClick={() => setIsCreateDiaryOpen(true)}
              iconType='diary'
              title='Complete your Diary'
              message={diaryStreak > 0 ? 
                `You’re are on a ${diaryStreak} day streak!` : 
                'Start your diary streak!'
              }
            />
        }
        {todaysDiaryEntry && 
            <ToDoItem 
              dataTestId='home-diary-to-do'
              headerDataTestId='home-diary-to-do-header'
              isLoading={isLoadingDiaryEntries}
              onClick={() => setIsCreateDiaryOpen(true)}
              iconType={getIconForMood()}
              title= {`You are ${moodToText(todaysDiaryEntry.mood)}${todaysDiaryEntry.mood >= 4 ? '!' : ''}`}
              message={diaryStreak > 0 ? 
                `You are on a ${diaryStreak} day streak!` : 
                ''
              }
            />
        }
      </>
      <CreateDiaryDialog userId={user?.id}
        isOpen={isCreateDiaryOpen}
        diaryEntryBeingEdited={todaysDiaryEntry}
        close={(shouldRefresh) => {
          setIsCreateDiaryOpen(false);
          if(shouldRefresh){
            loadDiaryEntries();
          }
        }} />
    </Box>
  );
}

