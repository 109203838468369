import React, { useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { 
  Box,
  Card,
  Typography,
  Stack,
  Checkbox,
  IconButton
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { RecoveryContext } from '@context';

import Section from './section';
import { LoadableList, LoadableText } from '@components/atoms';
import { useHistory } from 'react-router-dom';
import CreateSessionDialog from '../sessions/create/create-session-dialog';
import EmptySection from './empty-section';
import { ServicesContext, UserContext } from '@context';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { closeModal, openSuccessModal } from './../../../actions/modal';

import axios from 'axios';
import { svgToImage } from './../../../utils/images';
import { NewPostContext } from '@components/organisms';
import { Radio } from '@components/v2/atoms';

export default function Sessions(props){
  const SESSION_SECTION_HEIGHT_IN_PX = 71;
  const { getUserToken } = useContext(UserContext);
  const { api } = useContext(ServicesContext);
  const { openNewPostDialog } = useContext(NewPostContext);
  const { toggleExpansion, areSessionsExpanded } = props;
  const { activeDaysSessions, isLoadingActiveDaysSessions, loadActiveDaySessions, loadPrograms, plans, activeDay } = useContext(RecoveryContext);
  var [ isNewSessionDialogOpen, setIsNewSessionDialogOpen ] = useState(false);
  var [ numberOfRows, setNumberOfRows ] = useState(2);
  const history = useHistory();

  const Session = (props) => {
    const { session } = props;
    const dispatch = useDispatch();

    const getSetsRemaining = () => {
      var remainingCount = 0;
      if(session?.plan){
        session.plan.exercises.forEach(e => {
          e.sets.forEach(set => {
            if(!set.isComplete){
              remainingCount++;
            }
          });
        });
      }
      return remainingCount;
    };

    const savePost = async (data) => {
      const formData = new FormData();
      formData.append('message', data.message);
  
      if(data.image){
        formData.append('image', data.image);
      }
  
      if(data.organisation){
        formData.append('organisationId', data.organisation.id);
      }
  
      if(data.team){
        var teamIds = [ data.team.id ];
        formData.append('teamIds', teamIds);
      }
  
      await api.post({ data: formData, url: 'community/posts', getUserToken });
      dispatch(closeModal());
    };
  
    const openCreatePost = (i) => {
      openNewPostDialog({ 
        title: 'New Check-In', 
        placeholder: 'Your Post..', 
        buttonAction: savePost, 
        successToastMessage : 'Check-in Created!', 
        isAccessControlEnabled: true,
        isPrivateEnabled: true,
        initialImage: i
      });
    };
  
    const displayCompleteModal = () => {
      dispatch(openSuccessModal('Plan Completed For Today',  (<><p>Well done - you are now one step closer to your recovery goal!</p><p>Why not post about it to the Brace community?</p></>), 'confetti', 
        { text: 'Post To Community', 
          onClick: () => {
            axios({
              method: 'get',
              url: 'https://bracesocialprod.blob.core.windows.net/static/testing_svg.svg'
            })
              .then(async(response) => {
                var svg = response.data.replace('$$PLAN_NAME_GOES_HERE$$', session.plan.planName);
                var i = await svgToImage({ svg, mimetype: 'image/png', quality: 1, outputFormat: 'blob' });
  
                openCreatePost(i);
              });
  
          } }));
    };

    const completeAll = async () => {
      if(!session.plan.isComplete){
        await api.put({ url: `recovery/sessions/${session.id}/exercises/completeAll`, getUserToken });
        displayCompleteModal();
        loadActiveDaySessions(session.id);
        if(session.program?.id){
          loadPrograms(true);
        }
      }
    };

    return (
      <Card variant='outlined'
        sx={{ p: 1, display: 'flex', alignItems: 'center' }}
        data-testid={`recovery-session-card-${session?.plan?.planId}-${moment(session?.plannedStartDate).format('YYYYMMDD')}`}
      >
        <Radio
          onClick={completeAll}
          sx={{ p: 0 }}
          checked={session?.plan?.isComplete}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
        />
        <Box  sx={{ paddingLeft: 1, flexGrow: 1 }} onClick={() => history.push(`/recovery/sessions/${session.id}`)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component='div' variant='body1'>
              <LoadableText isLoading={isLoadingActiveDaysSessions} text={session?.plan?.planName} minWidth={200}/>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component='div' variant='body2'>
              <LoadableText isLoading={isLoadingActiveDaysSessions} text={`${session?.plan?.exercises.length} Exercises`} minWidth={200}/>
            </Typography>
            <Typography component='div' variant='body2'>
              <LoadableText isLoading={isLoadingActiveDaysSessions} text={`Sets Remaining : ${getSetsRemaining(session)}`} minWidth={100}/>
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={() => history.push(`/recovery/sessions/${session.id}`)} sx={{ paddingRight: 0 }}><ChevronRightIcon /></IconButton>
      </Card>
    );
  };

  const sessionsStackRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => { 
      var height = entries[0].contentRect.height;
      if(height){
        // -36 for the show more button
        setNumberOfRows(Math.max( 2,
          Math.floor((height - 36) / SESSION_SECTION_HEIGHT_IN_PX))
        );
      }
    });
    resizeObserver.observe(node);
  }, []);

  const sortedSessions = activeDaysSessions.sort(function(a, b) {
    if(a.plan?.isComplete && !b.plan?.isComplete){
      return 1;
    }
    if(!a.plan?.isComplete && b.plan?.isComplete){
      return -1;
    }
    return moment(b.updatedAt ?? b.createdAt).valueOf() - moment(a.updatedAt ?? a.createdAt).valueOf();
  });

  var title = `${activeDay.format('dddd')} Sessions`;

  if(activeDay.clone().isSame(moment(), 'day')){
    title = 'Todays Sessions';
  }

  if(activeDaysSessions.length > 0 && !isLoadingActiveDaysSessions){
    title = `${title} - ${Math.round((activeDaysSessions.filter(s => s.plan.isComplete).length * 100) / activeDaysSessions.length)}% Complete`
    ;
  }

  return (
    <Section id='sessions-section'
      buttonDataTestId='recovery-session-create-button'
      title={title}
      buttonAction={plans.length > 0 ? () => setIsNewSessionDialogOpen(true) : null}
      buttonIcon={<AddCircleIcon />}>
      {(!isLoadingActiveDaysSessions && activeDaysSessions.length == 0) &&
        <>
          <EmptySection 
            opacity= {plans.length === 0 ? 0.4 : 1}
            iconType='deadlift'
            iconSize='80px'
            message={plans.length > 0 ?
              'Sessions to be complete in a day will be shown here.' 
              : 'Create an exercise plan to add and complete sessions.'}
            action={plans.length > 0 ?
              () => setIsNewSessionDialogOpen(true)
              : null} />
        </>
      }

      <Stack spacing={1} sx={{ flexGrow: 1 }} ref={sessionsStackRef}>
        <LoadableList 
          numberOfRows={numberOfRows}
          isLoading={isLoadingActiveDaysSessions}
          rows={sortedSessions.slice(0, areSessionsExpanded ? 100 : numberOfRows)}
          getRow={(session) => <Session key={session.id} session={session}/> }
        />
        {((!isLoadingActiveDaysSessions && activeDaysSessions.length > numberOfRows) && !areSessionsExpanded) &&
         <Box 
           sx={{ display : 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}
           onClick={toggleExpansion}>
           
           <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show More</Typography>
           <ExpandMoreIcon sx={{ p: 0, fontSize: '1rem' }} />
         </Box>
        }
      </Stack>

      {(!isLoadingActiveDaysSessions && areSessionsExpanded) &&
            <Box 
              sx={{ display : 'flex', flexDirection: 'column', alignItems: 'center', color: 'text.secondary' }}
              onClick={toggleExpansion}>
              <ExpandLessIcon sx={{ p: 0, fontSize: '1rem' }} />
              <Typography sx={{ p: 0, lineHeight: 1 }} variant='caption'>Show Less</Typography>
           
            </Box>
      }

      <CreateSessionDialog isOpen={isNewSessionDialogOpen}
        close={(shouldRefresh) => {
          if(shouldRefresh){
            loadActiveDaySessions(true);
          }
          setIsNewSessionDialogOpen(false);}
        } />
    </Section>
  );
}

